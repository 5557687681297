<template>
  <div class="list-group-item info-block-contact">
    <div>
      <span>
        {{ $t('params.' + dataParam.param) | paramName }}:
        <span v-if="(dataParam.param === 'status' || dataParam.param === 'subscriber_status') && resultSource === 'HLR' "> {{ $t(`hlr.${dataParam.value}`) }} </span>
        <span class="emoji" v-html="$options.filters.isLink($options.filters.makeEmoji(dataParam.value.toString()))"
              v-else-if="dataParam.param !== 'last_registration_time' && dataParam.param !== 'name'"></span>
        <span v-else-if="dataParam.param === 'name'">{{ dataParam.value }}</span>

        <span v-else>{{moment(dataParam.value, 'X').format('DD.MM.YYYY HH:mm:ss') }}</span>
        <small v-if="dataParam.date"> (обновлено:   {{moment(dataParam.date, 'X').format('DD.MM.YYYY HH:mm:ss') }} )</small>
        <span>{{ addressLocation }}</span>
<!--        <button type="button" class="btn" v-if="location && isFullSearch" @click="setCenter"><i class="feather icon-crosshair"></i></button>-->
      </span>
      <span  style="width: 50px; text-align: right; line-height: 10px; display: flex;align-items: center; justify-content: flex-end;">
        <a :href="appLink" target="_blank" class="d-inline-block m-r-5" >
                  <i class="feather icon-external-link m-r-5 search"  v-tippy="{ placement : 'top',  arrow: true }" :content="$t('contact.gotoapp') + ' ' + app" v-if="appLink.length && ['isdn', 'username'].includes(dataParam.param)"></i>
        </a>
        <i class="feather icon-search search" :content="$t('search.search_by_val')" v-tippy="{ placement : 'top',  arrow: true }" v-if="params.includes(dataParam.param) && dataParam.param !== 'imsi' && dataParam.param !== 'imei' && dataParam.value !== this.currentResultValue"  @click="showModal()"></i>
        <i class="feather icon-crosshair search" :content="$t('search.show_on_map')" v-tippy="{ placement : 'top',  arrow: true }" v-else-if="location && isFullSearch"  @click="setCenter"></i>
        <i class="feather icon-crosshair search" :content="$t('search.show_on_map')" v-tippy="{ placement : 'top',  arrow: true }" v-else-if="resultSource === 'TelegramGeo' && dataParam.param === 'address' && typeof meta.payload.sources !== 'undefined' && meta.payload.sources.includes('TelegramGeo') && meta.parent"  @click="setMapMovementHistory"></i>
        <a :content="$t('geo.google_map')" v-tippy="{ placement: 'top', arrow: true }" class="m-r-10"  v-if="resultSource === 'Locator' && dataParam.param === 'address' && location" :href="`https://www.google.com/maps?ll=${location[0]},${location[1]}`" target='_blank'><i class="feather icon-map"></i></a>
        <i class="feather icon-crosshair search" :content="$t('search.show_on_map')" v-tippy="{ placement : 'top',  arrow: true }" v-if="resultSource === 'Locator' && dataParam.param === 'address' && location"  @click="setCenter"></i>

      </span>
    </div>

    <b-modal centered ref="search-param-modal" size="xl" hide-footer :title="this.$t('photo_fragment.search_params')+': '+ $t(`params.${dataParam.param}`)">
      <!--      <h4>{{ this.$t("photo_fragment.The search will be completed with the following options: online, stage 1")}}</h4>-->
      <div>
        <name-search-additional-settings :search-payload="payload"
                                         v-if="dataParam.param === 'name' && searchOptions.name.advanced" />
        <!-- <search-options
            v-if="paramsIncluded.includes(dataParam.param)"
            :expanded="true"
            :currentParam="dataParam.param"
            :iterative_search="payload.iterative_search"
            @setValidSearchOptions="setValidSearchOptions"
            @push="getPayload"
            :param="dataParam.param">
        </search-options> -->
        <options-search :param="dataParam.param" @push="getPayload" @ratesLoading="ratesLoading" @invalid="invalid => setValidSearchOptions(!invalid)" />
      </div>
      <div class="d-grid">

        <!--        TODO move to computed  -->

        <b-button class="mt-3" variant="primary"
                  block @click="searchByParam" v-if="!disallowFreeSpace"
                  :disabled="!searchOptionsValid || (dataParam.param === 'name' && searchButtonNeedDisable) || loadingRates">{{ $t("modal.search") }}</b-button>
        <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>

        <!--        TODO move to computed  -->

        <b-button class="mt-2" variant="secondary" block  @click="cancelModal">{{ $t("modal.cancel") }}</b-button>
      </div>
    </b-modal>


  </div>
</template>
<script>
import filters from "@/mixins/filters";
import {mapGetters} from "vuex";
import SearchOptions from "@/components/searchOptions";
import NameSearchAdditionalSettings from "@/components/nameSearchAdditionalSettings";
import _ from "underscore";
import optionsSearch from "@/components/search/optionsSearch.vue";
import imageSearch from '@/components/searchFromResult/imageSearch.vue';
import isdnSearch from '@/components/searchFromResult/isdnSearch.vue';
import telegramIdSearch from '@/components/searchFromResult/telegramIdSearch.vue';
import usernameSearch from '@/components/searchFromResult/usernameSearch.vue';
import emailSearch from '@/components/searchFromResult/emailSearch.vue';
import itnSearch from '@/components/searchFromResult/itnSearch.vue'
import carPlateNumSearch from '@/components/searchFromResult/carPlateNumSearch.vue'
import nameSearch from '@/components/searchFromResult/nameSearch.vue'
import moment from "moment";

export default {
  setup() {
    return {
      moment,
      toastTemplates: {
          'image': imageSearch,
          'isdn': isdnSearch,
          'telegram_id': telegramIdSearch,
          'username': usernameSearch,
          'email': emailSearch,
          'itn': itnSearch,
          'car_plate_num': carPlateNumSearch,
          'name': nameSearch
      }
     }
  },
  components: {SearchOptions, NameSearchAdditionalSettings, optionsSearch},
  data() {
    return {
      payload: {},
      visualOptions: [],
      paramsIncluded: ['isdn', 'image', 'email', 'car_plate_num', 'username', 'telegram_id', 'name', 'itn'],
      fullAlgorithms: {},
      searchOptionsValid: true,
      loadingRates: false,
      addressLocation: '',
      location: null
    }
  },
  props: ['dataParam', 'resultTitle', 'resultSource', 'parentItems', 'searchedValue', 'meta'],
  mixins: [filters],
  mounted() {
    this.fullAlgorithms = JSON.parse(localStorage.getItem('fullAlgorithms'));
    this.setAddressLocation();
  },
  computed: {
    // ...mapGetters('contacts', ['params','payload','currentResultValue', 'searchOptions']),
    ...mapGetters('contacts', ['params','currentResultValue', 'searchOptions', 'isFullSearch']),
    ...mapGetters("nameSearchAdditionalSettings", ["searchButtonNeedDisable", 'alt_values', 'filters', 'enableAltValues', 'enableAge', 'enableAddress']),
    ...mapGetters('ui', ['disallowFreeSpace']),
    ...mapGetters('auth', ['permissions']),
    options() {
      return this.searchOptions[this.dataParam.param];
    },
    app() {
      if (!_.isUndefined(this.resultSource)){
        if(this.resultSource === 'Viber') {
          return 'Viber'
        } else if(this.resultSource === 'WhatsApp') {
          return 'WhatsApp'
        } else if(this.resultSource === 'Telegram' || this.resultSource.toLowerCase().includes("telegram")) {
          return 'Telegram';
        }
      }
      return '';
    },
    appLink() {
      if (!_.isUndefined(this.resultSource)){
        if(this.resultSource === 'Viber' && this.dataParam.param === 'isdn') {
          return 'viber://chat?number='+ encodeURIComponent(this.dataParam.value);
        } else if(this.resultSource === 'WhatsApp') {
          return 'https://wa.me/' + this.dataParam.value.replace(/\+/, '');
        } else if(this.resultSource === 'Telegram' || this.resultSource.toLowerCase().includes("telegram")) {
          return 'https://t.me/' + this.dataParam.value.replace(/'/g, "");
        }
      }
      return '';
    },
  },
  methods: {



    ratesLoading(val){
      this.loadingRates = val
    },
    setValidSearchOptions(val){
      this.searchOptionsValid = val;
    },
    setAddressLocation() {
      if(!_.isUndefined(this.parentItems) && this.dataParam.param == 'address') {
        const addresses = _.where(this.parentItems, {param: 'address'});
        const lats = _.where(this.parentItems, {param: 'latitude'});
        const longs = _.where(this.parentItems, {param: 'longitude'});
        const indexAdress = _.findIndex(addresses, {value: this.dataParam.value});
        if(lats.length && longs.length && lats[indexAdress].value !== '-') {
          this.addressLocation = ` (${parseFloat(lats[indexAdress].value).toFixed(4)}, ${parseFloat(longs[indexAdress].value).toFixed(4)})`;
          // const translit = (new CyrillicToTranslit({preset: 'uk'})).transform(this.dataParam.value, '_').toLowerCase();
          // console.log(translit);
          this.location = [parseFloat(lats[indexAdress].value), parseFloat(longs[indexAdress].value)]
        }
      }
    },
    cancelModal(){
      this.$refs['search-param-modal'].hide();
    },
    showModal(){
      this.payload = {};
      this.payload.param = this.dataParam.param;
      this.payload.value = this.dataParam.value;
      if(this.dataParam.param === 'name') {
        this.setAdditionalParamsToNameSearch();
      }
      this.$refs['search-param-modal'].show();
      this.$store.dispatch('ui/getFreeSpaceInfo')
    },
    optionsForAnalytics(payload) {
      let params = [];
      if(payload.advanced) {
        params.push('Advanced');
      }
      if(payload.iterative_search) {
        params.push('Iterative');
      }
      if(!_.isUndefined(payload.filters)) {
        params.push('Filter')
      }
      return params.join(', ');
    },

    searchByParam() {
      let payload = {
        value: this.dataParam.value,
        param: this.dataParam.param,
      }
      payload = Object.assign(this.payload)
      this.payload.value = this.dataParam.value;
      this.payload.param = this.dataParam.param;
      payload.secondarySearch = true;
      this.$toast.success(this.toastTemplates[payload.param], {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
      this.$store.dispatch('contacts/search', {payload})
      this.$refs['search-param-modal'].hide();
      try {
        this.$matomo.trackEvent('Web Search Child Search '+ payload.param, 'WasClicked');
        let options = this.optionsForAnalytics(payload);
        if(options.length) {
          this.$matomo.trackEvent('Web Options '+ options, 'WasClicked');
        }
      } catch (e) {
        console.log('ignore error');
      }

    },
    getPayload(data) {
      if(typeof data.service_types !== 'undefined' && data.service_types.length === 0) {
        data.service_types = undefined;
      }
      this.payload = JSON.parse(JSON.stringify(Object.assign(this.payload, data)));
    },
    setAdditionalParamsToNameSearch() {

      if (typeof this.alt_values !== 'undefined' && this.alt_values.length !== 0) {
        if (this.enableAltValues) {
          this.payload.alt_values = this.alt_values;
        }
      }
      if (typeof this.filters !== 'undefined' && this.filters.length !== 0) {
        let temp = this.filters
        if (!this.enableAddress) {
          temp = this.filters.filter(el => el.param !== 'address' && el.param !== 'city')
        }
        if (!this.enableAge) {
          temp = this.filters.filter(el => el.param !== 'birthdate')
        }
        this.payload.filters = temp;
      }
    },
    setCenter() {
      this.$store.dispatch('full_search/resultCenter', this.location)
    },
    setMapMovementHistory(){
      this.$store.dispatch('movementHistory/resultCenter', this.location)
    },
  },
  watch: {
    alt_values: {
      handler() {
        this.setAdditionalParamsToNameSearch();
      },
      deep: true
    },
    filters: {
      handler() {
        this.setAdditionalParamsToNameSearch();
      },
      deep: true
    }
  },

}
</script>

<style lang="scss">
.info-block-contact .btn-link {
  font-size: 16px;
}
.emoji{
  img{
    width: 24px;
    height: 24px;
  }
}
.search{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.simple-result .list-group-item>div {
  padding: 0.5em 0.75em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.simple-result .list-group-item:not(:last-child)>div{
  border-bottom: 1px solid #ededed;
}
small {
  font-size: 12px;
}
</style>
<style>
.info-block-contact {
  word-break: break-word;
}
@media (max-width: 992px) {
  .info-block-contact a {
    max-width: calc(100vw - 120px);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    display: block;
    white-space: nowrap;
  }
}
</style>