<template>
    <div class="page-header card">
        <div class="row align-items-end justify-content-md-start">
            <div class="col-12 col-lg-8">
                <div class="page-header-title justify-content-md-start">
                    <i class="feather bg-c-blue d-none d-lg-inline-flex" :class="[currentIcon]"></i>
                    <div class="d-inline">
                        <h5>{{ $t('side_menu.'+currentText) }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'PageHeader',
    data() {
        return {
            classList: {
                'isdn': 'icon-phone',
                'mass_isdn': 'icon-phone-call',
                'email': 'icon-mail',
                'name': 'icon-user',
                'car_plate_num': 'iconCarSVG',
                'image': 'icon-image',
                'username': 'icon-at-sign',
                "search_history": 'icon-menu',
                'telegram_id': 'icon-message-square',
                'itn': 'icon-file-text',
                'full_search': 'icon-search',
                'simple_search': 'icon-search',
                'geo_search': 'icon-search',
                'geo_search_telegram': 'icon-map-pin',
                'car_vin': 'icon-VIN',
                'isdn_imei_imsi_search': 'icon-phone'

            },
            paramsText: {
                "isdn": "ISDN",
                "mass_isdn": "ISDN_set",
                "name": "name",
                "email": "email",
                "car_plate_num": "car_plate_num",
                "image": "image",
                "username": "username",
                "search_history": "search_history",
                'telegram_id': 'telegram_id',
                "itn": "itn",
                "full_search": "full_search",
                "simple_search": "simple_search_heading",
                'geo_search': 'geo_search',
                'car_vin': 'car_vin',
                'geo_search_telegram': 'geo_search_telegram',
                'isdn_imei_imsi_search': 'isdn_imei_imsi_search'
            }
        }
    },
    computed: {
        ...mapGetters('mainMenuState', ['currentParam']),
        currentIcon() {
            return typeof this.classList[this.currentParam] !== 'undefined' ? this.classList[this.currentParam] : this.classList['search_history'];
        },
        currentText() {
            return typeof this.paramsText[this.currentParam] !== 'undefined' ? this.paramsText[this.currentParam] : this.paramsText['search_history'];
        }
    }
}
</script>
<style scoped>
body .page-header.card {
    margin-left: 0;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0;
}

body .page-header-title h5 {
    height: 40px;
    line-height: 40px;
}
.iconCarSVG{
  background-image:url(../../../public/images/icon_carSVG.svg);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-VIN{
  background-image:url(../../../public/images/VIN.svg);
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>