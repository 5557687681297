import axios from "../../configs/axios";
export default {
    namespaced: true,
    state: {
        search_rates: [],
        services: []
    },
    getters: {
        search_rates: state => state.search_rates,
        servicesList: state => state.services
    },
    actions: {
        getSearchRates({commit}) {
            return new Promise((resolve, reject) => {
                axios({url: 'search_rates',  method: 'GET'})
                    .then((resp) => {
                        commit('setSearchRates', resp.data)
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getServices({commit}) {
            return new Promise(resolve => {
                axios.post('services', {})
                    .then( resp => {
                        commit('setServices', resp.data)
                        resolve(resp.data)
                    })
            })
        },
    },
    mutations: {
        setSearchRates(state, payload){
            state.search_rates = payload
        },
        setServices(state, payload) {
            let temp = payload.data.filter(item => item.name !== 'Locator')
            state.services = temp
        }
    }
}