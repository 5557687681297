<template>
  <div class="isdnImeiImsiSearch">
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
<!--      <div class="row">-->
<!--        <div class="col-md-3 searchContent">-->
<!--          <div class="selectContainer row">-->
<!--            <div class="selectLabel col-md-3">Шукати по</div>-->
<!--            <b-form-group class="col-md-6" style="margin-bottom: 0">-->
<!--              <b-form-select :placeholder="$t('group_form.order')" v-model="payload.param">-->
<!--                <b-form-select-option v-for="(item, k) in options" :key="k" :value="item">{{$t('params.'+ item)}}</b-form-select-option>-->
<!--              </b-form-select>-->
<!--            </b-form-group>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-9">-->

<!--          <div class="p-b-15 d-flex flex-column flex-md-row form-phone-wrapper" v-if="payload.param === 'isdn'">-->
<!--            <div class="phone-wrapper w-50">-->
<!--              <div class="form-group mb-0">-->
<!--                <vue-tel-input v-if="simpleInput" style="width: 100%" class="inputFocused"  v-model="val"-->
<!--                               :autoDefaultCountry="false" :dropdownOptions="{-->
<!--                        width: '20rem',-->
<!--                        showDialCodeInList: true,-->
<!--                        showDialCodeInSelection: true,-->
<!--                        showFlags: true,-->
<!--                        showSearchBox: true-->
<!--                      }" :inputOptions="{-->
<!--                        placeholder: $t('search.phoneNumberLabel'),-->
<!--                      }" mode="national" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"-->
<!--                               :validCharactersOnly="true"></vue-tel-input>-->
<!--                <input class="form-control" :placeholder="$t('search.phoneNumberLabel')" v-else type="text" autocomplete="off" v-model="phoneVal" />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="switcher-wrapper flex-shrink-sm-1 d-flex align-items-center p-t-10 pt-md-0">-->
<!--              <div class="switchContainer">-->
<!--                <div class="form-check form-switch" >-->
<!--                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"-->
<!--                         v-model="simpleInput">-->
<!--                  <label for="flexSwitchCheckChecked">{{ $t('settings.simplified_phone') }}</label>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->



<!--&lt;!&ndash;          <template v-if="payload.param === 'isdn'">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="form-group" style="margin-bottom: 0">&ndash;&gt;-->
<!--&lt;!&ndash;              <vue-tel-input style="width: 100%" class="inputFocused" v-model="val"&ndash;&gt;-->
<!--&lt;!&ndash;                             :autoDefaultCountry="false" :dropdownOptions="{&ndash;&gt;-->
<!--&lt;!&ndash;                    width: '20rem',&ndash;&gt;-->
<!--&lt;!&ndash;                    showDialCodeInList: true,&ndash;&gt;-->
<!--&lt;!&ndash;                    showDialCodeInSelection: true,&ndash;&gt;-->
<!--&lt;!&ndash;                    showFlags: true,&ndash;&gt;-->
<!--&lt;!&ndash;                    showSearchBox: true&ndash;&gt;-->
<!--&lt;!&ndash;                  }" :inputOptions="{&ndash;&gt;-->
<!--&lt;!&ndash;                    placeholder: $t('search.phoneNumberLabel'),&ndash;&gt;-->
<!--&lt;!&ndash;                  }" mode="auto" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"&ndash;&gt;-->
<!--&lt;!&ndash;                             :validCharactersOnly="true"></vue-tel-input>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--          <input  v-else type="text" id="inputSearch"  autocomplete="off"-->
<!--                 v-model="payload.value" :placeholder="`${$t('placeholders.enter')} ${$t('params.'+ payload.param)}`" class="form-control w-50" />-->
<!--        </div>-->
<!--      </div>-->




      <div class="d-flex">
        <div class=" searchContent">
          <div class="selectContainer m-r-10">
            <div class="selectLabel m-r-10">Шукати по</div>
            <b-form-group style="margin-bottom: 0; width: 80px">
              <b-form-select :placeholder="$t('group_form.order')" v-model="payload.param">
                <b-form-select-option v-for="(item, k) in options" :key="k" :value="item" style="padding-left: 12px">{{$t('params.'+ item)}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="col-md-9">

          <div class="d-flex flex-column flex-md-row form-phone-wrapper" v-if="payload.param === 'isdn'">
            <div class="phone-wrapper w-50">
              <div class="form-group mb-0">
                <vue-tel-input v-if="simpleInput" style="width: 100%" class="inputFocused"  v-model="val"
                               :autoDefaultCountry="false" :dropdownOptions="{
                        width: '20rem',
                        showDialCodeInList: true,
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showSearchBox: true
                      }" :inputOptions="{
                        placeholder: $t('search.phoneNumberLabel'),
                      }" mode="national" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"
                               :validCharactersOnly="true"></vue-tel-input>
                <input class="form-control" :placeholder="$t('search.phoneNumberLabel')" v-else type="text" autocomplete="off" v-model="phoneVal" />
              </div>
            </div>
            <div class="switcher-wrapper flex-shrink-sm-1 d-flex align-items-center p-t-10 pt-md-0">
              <div class="switchContainer">
                <div class="form-check form-switch m-b-0" >
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                         v-model="simpleInput">
                  <label for="flexSwitchCheckChecked">{{ $t('settings.simplified_phone') }}</label>
                </div>
              </div>
            </div>
          </div>



          <!--          <template v-if="payload.param === 'isdn'">-->
          <!--            <div class="form-group" style="margin-bottom: 0">-->
          <!--              <vue-tel-input style="width: 100%" class="inputFocused" v-model="val"-->
          <!--                             :autoDefaultCountry="false" :dropdownOptions="{-->
          <!--                    width: '20rem',-->
          <!--                    showDialCodeInList: true,-->
          <!--                    showDialCodeInSelection: true,-->
          <!--                    showFlags: true,-->
          <!--                    showSearchBox: true-->
          <!--                  }" :inputOptions="{-->
          <!--                    placeholder: $t('search.phoneNumberLabel'),-->
          <!--                  }" mode="auto" :defaultCountry="countryForPhoneNumber" styleClasses="input" @input="getValidate"-->
          <!--                             :validCharactersOnly="true"></vue-tel-input>-->
          <!--            </div>-->
          <!--          </template>-->
          <input  v-else type="text" id="inputSearch"  autocomplete="off"
                  v-model="payload.value" :placeholder="`${$t('placeholders.enter')} ${$t('params.'+ payload.param)}`" class="form-control w-50" />
        </div>
      </div>









      <div class="border-checkbox-section m-t-15 m-l-10">
        <div class="border-checkbox-group border-checkbox-group-primary">
          <input type="checkbox" class="border-checkbox" v-model="enableLocationSearch" id="enableLocationSearchId"/>
          <label class="border-checkbox-label" for="enableLocationSearchId">{{$t('monitoringLocation.get_coordinates')}}</label>
        </div>
      </div>

      <div class="d-grid m-t-15" v-if="!disallowFreeSpace">
        <b-button variant="primary" class="btn" type="submit" :disabled="$v.payload.$invalid">
          {{ $t("search.search") }}
        </b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>


    <b-modal v-model="modalDialog" centered :title="$t('search.query_exist')" hide-footer has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal>
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <b-button variant="primary" class="mt-3" @click="resolveExistResult('update')"   block >{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2"  :disabled="!showBtn" @click="resolveExistResult('open')">{{$t('search.open')}}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import openResult from "@/mixins/openResult";
import {isValidPhoneNumber} from 'libphonenumber-js';
import {email, maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";


export default {
  name: "isdnImeiImsiSearch",
  mixins: [validationMixin, openResult],
  components: {
    VueTelInput,
  },
  data() {
    return {
      payload: {
        param: 'isdn',
        value:'',
        cache_search_enabled: false,
        services: ['Locator'],
        online_search_enabled: true,
        isdnImeiImsiSearch: true,
      },
      modalDialog: false,
      showBtn: true,
      invalid: true,
      options: ['isdn', 'imsi', 'imei' ],
      input: {},
      countryForPhoneNumber: localStorage.getItem("selected_country_isdn") || 'UA',
      val: "",
      country: {},
      phoneObj: {},
      phoneRaw: "",
      enableLocationSearch: false,
      simpleInput: true,
      phoneVal: "+",
    }
  },
  validations() {
    let validation = {
      payload: {
        param: {
          required,
        },
      },
    };
      validation.payload.value = { required };
      if (this.payload.param === "isdn") {
        validation.payload.value = {
          required,
          validPhone: (val) => {
            if (!this.simpleInput) {
              return isValidPhoneNumber(val)
            } else {
              return typeof this.input.valid === "undefined" ? false : this.input.valid
            }
          }
        }
      }
      if (this.payload.param === "imei") {
        validation.payload.value = {
          required,
          numeric,
          lengthI: () =>
              this.payload.value.length >= 14 && this.payload.value.length <= 15,
        };
      }
      if (this.payload.param === "imsi") {
        validation.payload.value = {
          required,
          numeric,
          lengthI: () => this.payload.value.length === 15,
        };
      }
    return validation;
  },
  computed:{
    ...mapGetters("ui", ['disallowFreeSpace']),
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    phoneVal(val) {
      if(!this.simpleInput) {
        this.payload.value = val.replace(/^(\+)|\D/g, "$1");
      }
    },
  },
  methods:{
    getValidate(phone, phoneObj) {
      this.countryForPhoneNumber = localStorage.getItem('selected_country_isdn');
      this.phoneObj = phoneObj;
      this.phoneRaw = phone;
      let payload = JSON.parse(JSON.stringify(phoneObj));
      this.input = payload;
      this.payload.value = payload.number || "";
      if(typeof phoneObj.country !== 'undefined') {
        localStorage.setItem("selected_country_isdn", phoneObj.country.iso2)
      }
    },

    beforeSearch() {
      let value = this.payload.value;
      if (value.substring(0, 1) === '@') {
        value = value.substring(1);
      }
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `isdnImeiImsiSearch_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },
    addSearch() {
      let value = this.payload.value;
      if (value.substring(0, 1) === '@') {
        value = value.substring(1);
      }
      let payload = Object.assign({}, this.payload);

      let service_options =  {
        service_options: {
          Locator: {
            request_options: {
              location: true
            }
          }
        }
      }

      if (this.enableLocationSearch){
        payload = Object.assign(payload, service_options)
      }

      payload = Object.assign(payload, {value})
      this.$store.dispatch("contacts/search", {
        payload,
      }).then(() => {
        this.$store.dispatch('ui/getFreeSpaceInfo')
      });
      this.payload.value = null;
      this.val = "";
      this.val = "";
      this.phoneVal = '+';
    },

    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    resolveExistResult(value) {
      let result = {
        keyVal: `isdnImeiImsiSearch_${this.payload.value}_${this.user.login}`,
        loaded: true,
      };
      switch (value) {
        case "open":
          this.openResult(result);
          break;
        case "update":
          this.addSearch(false);
          this.modalDialog = false;
          break;
        case "new":
          this.addSearch(true);
          this.modalDialog = false;
          break;
      }
    },
  },

}
</script>

<style lang="scss">
@media screen and (max-width: 768px)  {
  .row {
    .col-md-8 {
      padding-right: 0;
    }
  }
}
.isdnImeiImsiSearch{
  .row{
    margin: 0;
    .col-md-6 {
      padding: 0;
    }
    .col-md-8 {
      padding-left: 0;
    }
  }
  .searchContent{
    padding-left: 0;
    padding-right: 0;
  }
  .selectContainer{

    display: flex;
    align-items: center;
    .selectLabel{
      //border: 1px solid rgb(204, 204, 204);
      border-right: none;
      height: 34px;
      display: flex;
      justify-content: start;
      align-items: center;
      //padding-left: 16px;
      //padding-right: 16px;
      padding-left: 0;
      padding-right: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      //border-right: none;
    }
    .custom-select{
      border-color: rgb(204, 204, 204);
      //border-left: none;
      height: 34px;
      width: 100%;
      border-radius: 2px;
      padding-left: 7px;
      //border-top-right-radius: 2px;
      //border-bottom-right-radius: 2px;
    }
  }
}


</style>